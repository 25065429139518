import './Open.css';

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { fetchApi } from '../../API/api';
import { formattedDate } from '../../utils/formatingDate';
import { formatNumber } from '../../utils/formatingDate';

import Button from '../../Components/Buttons/Button'

import Transaction from '../../Components/MiniBlocks/Transaction';
import Detail from '../../Components/MiniBlocks/Detail';
import Device from '../../Components/MiniBlocks/Device';
import DisputeModal from '../../Components/Modals/Dispute/DisputeModal';

import waiting from '../../Assets/row/waiting.svg';
import accept from '../../Assets/row/cardPlus.svg';
import cancel from '../../Assets/row/cardRemove.svg';
import receipt from '../../Assets/svg/receipt.svg'
import add from '../../Assets/row/addCircle.svg'
import close from '../../Assets/row/closeCircle.svg'

export default function Dispute({ windowWidth }) {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [openModal, setOpenModal] = useState(null);
    const [reasons, setReasons] = useState([]);

    const preloadImages = (imageUrls) => {
        const promises = imageUrls.map((url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = url;
                img.onload = resolve;
                img.onerror = reject;
            });
        });
        return Promise.all(promises);
    };

    useEffect(() => {
        const fetchDisputeData = async () => {
            try {
                await preloadImages([waiting, accept, cancel, receipt, add, close]);
                setImagesLoaded(true);

                const result = await fetchApi({
                    url: `/api/v1/dashboard/dispute/${id}`,
                });
                setData(result);
            } catch (error) {
                console.error('Ошибка при загрузке данных или изображений:', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchReasonData = async () => {
            try {
                const result = await fetchApi({
                    url: `/api/v1/dashboard/reason`,
                });
                setReasons(result);
                console.log(result);
            } catch (error) {
                console.error('Ошибка при загрузке данных или изображений:', error);
            } finally {
            }
        };
        fetchDisputeData();
    }, [id]);

    useEffect(() => {
        const fetchReasonData = async () => {
            try {
                const result = await fetchApi({
                    url: `/api/v1/dashboard/reason`,
                });
                setReasons(result);
                console.log(result);
            } catch (error) {
                console.error('Ошибка при загрузке данных или изображений:', error);
            }
        };

        if (data?.condition === null) {
            fetchReasonData();
        }
    }, [data])

    const getStatusImage = () => {
        if (data?.condition === null) return waiting;
        return data?.condition ? accept : cancel;
    };

    const closeModal = () => {
        setOpenModal(null);
    }

    return (
        <div className='page'>
            {(openModal === true || openModal === false) &&
                <DisputeModal
                    onClose={closeModal}
                    windowWidth={windowWidth}
                    typeDispute={openModal}
                    reasons={reasons}
                    data={data}
                    setData={setData}
                    setLoading={setLoading}
                />
            }
            <div className="headerBlock">
                <div className="headerTop">
                    <div className="headerLeft">
                        {loading || !imagesLoaded ? (
                            <h1>Спор ID: <div className="loading-pulse" style={{ height: '57px', width: "200px" }} /></h1>
                        ) : (
                            <h1>Спор ID: {data?.id?.length > 10 ? `${data.id.slice(0, 10)}...` : data?.id}</h1>
                        )}
                    </div>
                    {data?.condition === null && (
                        <div className="headerRigth">
                            <Button
                                type={'colored'}
                                style={{ width: 'auto' }}
                                tb={10}
                                rl={16}
                                onClick={() => setOpenModal(true)}
                            >
                                <img src={add} alt="Иконка вывода модалки" />
                                <p style={{ color: '#FFFFFF', fontWeight: '600' }}>
                                    Разрешить
                                </p>
                            </Button>
                            <Button
                                type={'white'}
                                style={{ width: 'auto' }}
                                tb={10}
                                rl={16}
                                onClick={() => setOpenModal(false)}
                            >
                                <img src={close} alt="Иконка вывода модалки" />
                                <p style={{ color: '#2B2B2A', fontWeight: '600' }}>
                                    Отклонить
                                </p>
                            </Button>
                        </div>
                    )}
                </div>
                <div className="headerBottom">
                    <div className="status">
                        <div className="statusIco">
                            {loading || !imagesLoaded ? (
                                <div className="loading-pulse" style={{ height: '43px', width: "32px" }} />
                            ) : (
                                <img src={getStatusImage()} alt='Статус спора' />
                            )}
                        </div>
                        <div className="statusText">
                            {loading || !imagesLoaded ? (
                                <>
                                    <div className="loading-pulse" style={{ height: '33px', width: "150px" }} />
                                    <div className="loading-pulse" style={{ height: '19px', width: "150px" }} />
                                </>
                            ) : (
                                <>
                                    <p>{data?.condition === null ? 'Открытый спор' : data?.condition ? 'Спор принят' : 'Спор отклонен'}</p>
                                    <span>{formattedDate(data?.create)}</span>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="amount">
                        <span>Сумма спора</span>
                        {loading || !imagesLoaded ? (
                            <div className="loading-pulse" style={{ height: '22px', width: "80px" }} />
                        ) : (
                            <p>{formatNumber(data?.amount)} {data?.payment?.currency}</p>
                        )}
                    </div>
                    <div className="amount">
                        <span>Сумма сделки</span>
                        {loading || !imagesLoaded ? (
                            <div className="loading-pulse" style={{ height: '22px', width: "80px" }} />
                        ) : (
                            <p>{formatNumber(data?.payment?.amount)} {data?.payment?.currency}</p>
                        )}
                    </div>
                    {loading || !imagesLoaded ? (
                        <div className="file">
                            <div className="loading-pulse" style={{ height: '46px', width: "165px" }} />
                            <div className="loading-pulse" style={{ height: '22px', width: "175px" }} />
                        </div>
                    ) :
                        data.files.map((file, index) => {
                            // Предполагаем, что file.file содержит строку в формате base64, например: "data:image/jpeg;base64,..."
                            const [fileType, base64Content] = file.file.split(';base64,');

                            const openFileInNewTab = () => {
                                const newWindow = window.open('', '_blank');
                                if (!newWindow) return; // Проверка на случай блокировки всплывающих окон

                                if (fileType.startsWith('data:image/')) {
                                    newWindow.document.write(`
                                    <html>
                                        <head>
                                            <style>
                                                body { 
                                                    margin: 0; 
                                                    display: flex; 
                                                    justify-content: center; 
                                                    align-items: center; 
                                                    height: 100vh; 
                                                    background: #f0f0f0;
                                                }
                                                img { 
                                                    max-width: 100%; 
                                                    max-height: 100%; 
                                                    object-fit: contain; 
                                                }
                                            </style>
                                            <title>${file.file_name}</title>
                                        </head>
                                        <body>
                                            <img src="${file.file}" alt="${file.file_name}" />
                                        </body>
                                    </html>
                                `);
                                } else if (fileType === 'data:application/pdf') {
                                    newWindow.document.write(`
                                    <html>
                                        <head>
                                            <title>${file.file_name}</title>
                                            <style>
                                                body { margin: 0; height: 100vh; }
                                                iframe { width: 100%; height: 100%; border: none; }
                                            </style>
                                        </head>
                                        <body>
                                            <iframe src="${file.file}" title="${file.file_name}"></iframe>
                                        </body>
                                    </html>
                                `);
                                }
                                newWindow.document.close();
                            };

                            return (
                                <div className="file" key={index}>
                                    <Button
                                        type={'white'}
                                        style={{ width: 'auto' }}
                                        tb={10}
                                        rl={16}
                                        onClick={openFileInNewTab}
                                    >
                                        <img src={receipt} alt="Иконка вывода модалки" />
                                        <span style={{ color: '#2B2B2A', fontWeight: '400' }}>
                                            {file.file_name.length > 10
                                                ? `${file.file_name.slice(0, 3)}...${file.file_name.slice(-7)}`
                                                : file.file_name}
                                        </span>
                                    </Button>
                                    <span>{formattedDate(file?.date_input)}</span>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div className="pageInfo">
                <Transaction loading={loading} payment={data?.payment} card={data?.card} device={data?.device} />
                <Device loading={loading} device={data?.device} />
                <Detail loading={loading} payment={data?.payment} card={data?.card} />
            </div>
        </div>
    );
}