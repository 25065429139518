import '../Pages.css'
import React, { useState, useEffect } from 'react'
import { fetchApi } from '../../API/api'
import { formatNumber } from '../../utils/formatingDate'

import Table from '../../Components/Table/Table'
import Row from '../../Components/Table/Row'
import Button from '../../Components/Buttons/Button'

import calendar from '../../Assets/svg/calendarGrey.svg'

export default function Merchants() {

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchMerchantData = async () => {
        try {
            const result = await fetchApi({
                url: `/api/v1/dashboard/merchant`,
                params: { page: currentPage, page_size: pageSize },
            });
            setData(result);
        } catch (error) {
            console.error('Ошибка при загрузке данных:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        if (pageSize !== null) {
            fetchMerchantData();
        }
    }, [currentPage, pageSize]);

    const columns = [
        {
            header: 'Название',
            component: ({ data }) => <Row title={data.name} />
        },
        {
            header: 'Баланс',
            component: ({ data }) => <Row
                currency={"USDT"}
                title={formatNumber(data?.balance) ?? 'N/A'}
            />
        },
        {
            header: 'Суточный объем',
            component: ({ data }) => <Row
                currency={"USDT"}
                limit={true}
                limit_have={data?.volume}
                limit_max={10000}
                title={formatNumber(data?.volume) ?? 'N/A'}
            />
        },
    ];


    return (
        <div className='page'>
            <div className="tableWithBtn" id='tableTran'>
                <Table
                    title={'Мерчанты'}
                    columns={columns}
                    data={data?.results || []}
                    totalCount={data?.total_pages || 0}
                    itemsPerPage={pageSize}
                    rowsLoading={pageSize}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                    isLoading={loading}
                    onRowClick={true}
                    onEdit={false}
                    onStop={false}
                />
            </div>
        </div>
    )
}
