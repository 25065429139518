import { formattedDate } from '../../utils/formatingDate'
import { formatCardNumber } from '../../utils/formatingDate'
import { formatNumber } from '../../utils/formatingDate'

import Row from '../../Components/Table/Row'

import waiting from '../../Assets/row/waiting.svg'
import accept from '../../Assets/row/cardPlus.svg'
import cancel from '../../Assets/row/cardRemove.svg'

export const columnsOffice = [
    {
        header: 'Статус',
        component: ({ data }) => (
            <Row
                flex_out={'flex'}
                gap_out={15}
                icon={data.status === 'CL' ? cancel : data.status === 'AC' ? accept : waiting}
                title={data.status === 'CL' ? 'Сделка отклонена' : data.status === 'AC' ? 'Сделка завершена' : 'Создана новая сделка'}
                text={formattedDate(data.create)}
            />
        ),
        mobileHidden: true,
        desktopHidden: false,
    },
    {
        header: 'Статус',
        component: ({ data }) => (
            <Row
                flex_out={'flex'}
                gap_out={15}
                icon={data.status === 'CL' ? cancel : data.status === 'AC' ? accept : waiting}
            />
        ),
        mobileHidden: false,
        desktopHidden: true,
    },
    {
        header: 'Сумма',
        component: ({ data }) => (
            <Row
                usdt={true}
                currency={data.currency}
                title={formatNumber(data?.amount) ?? 'N/A'}
                text={formatNumber(data?.amount_usdt) ?? 'N/A'}
            />
        ),
        mobileHidden: false,
        desktopHidden: false,
    },
    {
        header: 'Карта',
        component: ({ data }) => (<Row title={data?.card ? (
            data.type === "C2C" ? formatCardNumber(data.card?.card_number) :
                data.type === "SBP" ? data.card?.phone_number :
                    data.type === "TPay" ? data.card?.phone_number :
                        data.type === "SberPay" ? data.card?.phone_number :
                            data.type === "NSPK" ? "Оплата по ссылке" :
                                "Данные отсутствуют") : 'Данные отсутствуют'} text={data.card?.bank} iconBank={data.card?.bank_logo} />
        ),
        mobileHidden: false,
        desktopHidden: false,
    },
    {
        header: 'Устройство',
        component: ({ data }) => (
            <Row
                title={data.device.name}
                text={data.device.token}
            />
        ),
        mobileHidden: true,
        desktopHidden: false,
    },
];

export const columnsAdminSupport = [
    {
        header: 'Статус',
        component: ({ data }) => (
            <Row
                flex_out={'flex'}
                gap_out={15}
                icon={data.status === 'CL' ? cancel : data.status === 'AC' ? accept : waiting}
                title={data.status === 'CL' ? 'Сделка отклонена' : data.status === 'AC' ? 'Сделка завершена' : 'Создана новая сделка'}
                text={formattedDate(data.create)}
            />
        ),
        mobileHidden: true,
        desktopHidden: false,
    },
    {
        header: 'Статус',
        component: ({ data }) => (
            <Row
                flex_out={'flex'}
                gap_out={15}
                icon={data.status === 'CL' ? cancel : data.status === 'AC' ? accept : waiting}
            />
        ),
        mobileHidden: false,
        desktopHidden: true,
    },
    {
        header: 'Сумма',
        component: ({ data }) => (
            <Row
                usdt={true}
                currency={data.currency}
                title={formatNumber(data?.amount) ?? 'N/A'}
                text={formatNumber(data?.amount_usdt) ?? 'N/A'}
            />
        ),
        mobileHidden: false,
        desktopHidden: false,
    },
    {
        header: 'Карта',
        component: ({ data }) => (<Row title={data?.card ? (
            data.type === "C2C" ? formatCardNumber(data.card?.card_number) :
                data.type === "SBP" ? data.card?.phone_number :
                    data.type === "TPay" ? data.card?.phone_number :
                        data.type === "SberPay" ? data.card?.phone_number :
                            data.type === "NSPK" ? "Оплата по ссылке" :
                                "Данные отсутствуют") : 'Данные отсутствуют'} text={data.card?.bank} iconBank={data.card?.bank_logo} />
        ),
        mobileHidden: false,
        desktopHidden: false,
    },
    {
        header: 'Обработка',
        component: ({ data }) => (
            <Row
                title={data.merchant}
                text={data.office}
            />
        ),
        mobileHidden: true,
        desktopHidden: false,
    },
];