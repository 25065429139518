import './MiniBlocks.css';
import React, { useEffect, useState } from 'react';

import { formatCardNumber } from '../../utils/formatingDate';
import { formatNumber } from '../../utils/formatingDate';

import waiting from '../../Assets/row/waiting.svg'
import accept from '../../Assets/row/cardPlus.svg'
import cancel from '../../Assets/row/cardRemove.svg'
import dollar from '../../Assets/row/dollar.svg'
import bank from '../../Assets/row/bank.svg'
import deviceSvg from '../../Assets/row/device.svg'

export default function Transaction({ loading, payment, card, device }) {

    const [imageLoaded, setImageLoaded] = useState(false);

    const preloadImages = (imageUrls) => {
        const validUrls = imageUrls.filter(url => url && typeof url === 'string');

        const promises = validUrls.map((url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = url;
                img.onload = () => resolve(url);
                img.onerror = () => reject(new Error(`Failed to load image: ${url}`));
            });
        });
        return Promise.all(promises);
    };

    useEffect(() => {
        const loadingImage = async () => {
            try {
                const imageUrls = [waiting, accept, cancel, dollar, bank, deviceSvg, card?.bank_logo];
                const loadedUrls = await preloadImages(imageUrls);
                console.log("Loaded images:", loadedUrls);
            } catch (error) {
                console.error(error.message);
            } finally {
                setImageLoaded(true);
            }
        };
        loadingImage();
    }, [card]);

    const requisite = (payment?.type === "C2C" ? formatCardNumber(card?.card_number) :
        payment?.type === "SBP" ? card?.phone_number :
            payment?.type === "TPay" ? card?.phone_number :
                payment?.type === "SberPay" ? card?.phone_number :
                    payment?.type === "NSPK" ? "Оплата по ссылке" :
                        "Данные отсутствуют")


    return (
        <div className="miniBlock">
            <h1>Сделка</h1>
            <div className="miniBlockRows">
                <div className="miniBlockRow">
                    <div className="miniBlockRowIcon">
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '24px', width: "24px" }} />
                        ) : (
                            <img src={payment?.status === 'CL' ? cancel : payment?.status === 'AC' ? accept : waiting} alt="Состояние сделки" />
                        )}
                    </div>
                    <div className="miniBlockRowText">
                        <span>Статус сделки</span>
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '22px', width: "200px" }} />
                        ) : (
                            <p>{payment?.status === 'CL' ? 'Сделка отклонена' : payment?.status === 'AC' ? 'Сделка завершена' : 'Создана новая сделка'}</p>
                        )}
                    </div>
                </div>
                <div className="miniBlockRow">
                    <div className="miniBlockRowIcon">
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '24px', width: "24px" }} />
                        ) : (
                            <img src={dollar} alt="Сумма сделки" />
                        )}
                    </div>
                    <div className="miniBlockRowText">
                        <span>Сумма</span>
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '22px', width: "100px" }} />
                        ) : (
                            <p>{formatNumber(payment?.amount)} {payment?.currency}</p>
                        )}
                    </div>
                </div>
                <div className="miniBlockRow">
                    <div className="miniBlockRowIcon">
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '24px', width: "24px" }} />
                        ) : (
                            <img src={card?.bank_logo ? card.bank_logo : bank} alt="Банк" className={card?.bank_logo ? 'round' : ''} />
                        )}
                    </div>
                    <div className="miniBlockRowText">
                        <span>Реквизит</span>
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '22px', width: "220px" }} />
                        ) : (
                            <p>{requisite}</p>
                        )}
                    </div>
                </div>
                <div className="miniBlockRow">
                    <div className="miniBlockRowIcon">
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '24px', width: "24px" }} />
                        ) : (
                            <img src={deviceSvg} alt="Устройство" />
                        )}
                    </div>
                    <div className="miniBlockRowText">
                        <span>Устройство</span>
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '22px', width: "150px" }} />
                        ) : (
                            <p>{device?.name}</p>
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
}