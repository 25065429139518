import './Table.css';

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import edit from '../../Assets/svg/edit.svg';
import resume from '../../Assets/svg/resume.svg';
import pause from '../../Assets/svg/pause.svg';

const Table = ({
    title,
    columns,
    data = null,
    totalCount = 0,
    onRowClick,
    itemsPerPage = 5,
    onEdit,
    onStop,
    currentPage,
    setCurrentPage,
    isLoading,
    className,
    rowsLoading = 10,
    setPageSize,
    stop = false,
}) => {
    const [stopRequisite, setStopRequisite] = useState(stop);
    const blockRef = useRef(null);
    const tableRef = useRef(null);
    const navigate = useNavigate();

    const totalPages = totalCount;


    const calculateRows = () => {
        if (!tableRef.current && !blockRef.current) return;

        const blockHeight = blockRef.current.clientHeight;
        const headerHeight = tableRef.current.querySelector('thead')?.offsetHeight || 0;
        const availableHeight = blockHeight - tableRef.current.offsetTop - headerHeight;

        const rowHeight = tableRef.current.querySelector('tbody tr')?.offsetHeight || 64;

        const calculatedRows = Math.floor(availableHeight / rowHeight) - 1;
        if (typeof setPageSize === 'function') {
            setPageSize(calculatedRows);
        } else {
            console.error('setPageSize is not a function');
        }
        console.log(calculatedRows);
    };

    useEffect(() => {
        calculateRows();

        window.addEventListener('resize', calculateRows);
        return () => window.removeEventListener('resize', calculateRows);
    }, []);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const renderPagination = () => {
        const pageButtons = [];
        const pagesAround = 2;

        pageButtons.push(
            <button
                key={1}
                onClick={() => handlePageChange(1)}
                className={`paginationBtn ${currentPage === 1 ? 'active' : ''}`}
            >
                1
            </button>
        );

        let startPage = Math.max(2, currentPage - pagesAround);
        let endPage = Math.min(totalPages - 1, currentPage + pagesAround);

        if (startPage > 2) {
            pageButtons.push(<span key="ellipsis-start" className="ellipsis">...</span>);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageButtons.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={`paginationBtn ${currentPage === i ? 'active' : ''}`}
                >
                    {i}
                </button>
            );
        }

        if (endPage < totalPages - 1) {
            pageButtons.push(<span key="ellipsis-end" className="ellipsis">...</span>);
        }

        if (totalPages > 1) {
            pageButtons.push(
                <button
                    key={totalPages}
                    onClick={() => handlePageChange(totalPages)}
                    className={`paginationBtn ${currentPage === totalPages ? 'active' : ''}`}
                >
                    {totalPages}
                </button>
            );
        }

        return (
            <div className="pagination">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="pageSwitch"
                >
                    Назад
                </button>
                <div className="pageNumbers">{pageButtons}</div>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="pageSwitch"
                >
                    Вперед
                </button>
            </div>
        );
    };

    const handleRowClick = (row) => {
        const type = localStorage.getItem("fp_type");
        if (type === "Admin") {
            if (title === "Споры") {
                navigate(`/disputes/${row.id}`);
            }
        }
        console.log('Переход к:', row);
    };

    const handleEdit = (row) => {
        console.log('Редактирование:', row);
    };


    const width = {
        "Статус": ["40%", "35%"],
        "Сумма": ["20%", "10%"],
        "Карта": ["35%", "20%"],
        "Устройство": ["30%", "25%"],
        "Сделка": ["30%", "25%"],
        "Название": ["50%", "50%"],
        "Баланс": ["20%", "40%"],
        "Конверсия": ["15%", "15%"],
        "Объем": ["25%", "25%"],
        // "Cостояние": ["10%", "10%"],
        "Действие": ["10%", "10%"],
        "Обработка": ["10%", "20%"],
        "Суточный объем": ["50%", "50%"]
    }

    return (
        <div ref={blockRef} className={`universal-table ${className}`}>
            <h1>{title}</h1>
            <div className='table-wrapper'>
                <table ref={tableRef} style={{ '--columns-count': columns.length }}>
                    <thead>
                        <tr>
                            {columns.map((col, index) => (
                                <th key={index} className={`${col.mobileHidden && 'mobile-hidden'} ${col.desktopHidden && 'desktop-hidden'}`}>
                                    {col.header}
                                </th>
                            ))}
                            {(onEdit || onStop) && <th></th>}
                        </tr>
                    </thead>
                    <tbody className="tableBody">
                        {isLoading && (
                            Array.from({ length: rowsLoading }).map((_, rowIndex) => (
                                <tr key={rowIndex} className="row">
                                    {columns.map((col, colIndex) => (
                                        <td key={colIndex} className={`${col.mobileHidden && 'mobile-hidden'} ${col.desktopHidden && 'desktop-hidden'}`}>
                                            <div className="loading-pulse" style={{ height: '22px', width: width[col.header] ? width[col.header][0] : '50%', marginBottom: "4px" }} />
                                            <div className="loading-pulse" style={{ height: '17px', width: width[col.header] ? width[col.header][1] : '50%' }} />
                                        </td>
                                    ))}
                                    {(onEdit || onStop) && (
                                        <td className="actions">
                                            <div className="loading-pulse" style={{ height: '20px', width: '60px' }} />
                                        </td>
                                    )}
                                </tr>
                            ))
                        )}
                        {!isLoading && data !== null && data.map((row, rowIndex) => (
                            <tr
                                key={rowIndex}
                                onClick={() => onRowClick && handleRowClick(row)}
                                className={`row ${onRowClick ? 'clickable-row' : ''}`}
                            >
                                {columns.map((col, colIndex) => (
                                    <td key={colIndex} className={`${col.mobileHidden && 'mobile-hidden'} ${col.desktopHidden && 'desktop-hidden'}`}>
                                        {col.component({ data: row })}
                                    </td>
                                ))}
                                {(onEdit || onStop) && (
                                    <td className="actions">
                                        {onEdit && (
                                            <img
                                                src={edit}
                                                alt="Иконка редактирования данных"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEdit(row);
                                                }}
                                            />
                                        )}
                                        {onStop && (
                                            <img
                                                src={stopRequisite ? pause : resume}
                                                alt="Иконка возобновления или паузы работы реквизитов"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setStopRequisite(!stopRequisite);
                                                }}
                                            />
                                        )}
                                    </td>
                                )}
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>
            {totalPages > 1 && renderPagination()}
        </div>
    );
};

export default Table;