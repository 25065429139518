import React from 'react'
import '../Pages.css'

import Table from '../../Components/Table/Table'
import Row from '../../Components/Table/Row'
import Button from '../../Components/Buttons/Button'

import calendar from '../../Assets/svg/calendarGrey.svg'

export default function Statistics() {
    const data = [
        { транзакция: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        { пользователь: 'Фетинин Никита Геннадьевич', сумма: '533', статус: 5, адрес: '58% 22/38' },
        // Дополнительные данные...
    ];

    const columns = [
        {
            header: 'Сделка',
            component: ({ data }) => (
                <Row
                    title={''}
                />
            ),
        },
        {
            header: 'Сумма',
            component: ({ data }) => <Row />,
        },
        {
            header: 'Карта',
            component: ({ data }) => <Row />, // Предполагаем статичный статус
        },
    ];

    return (
        <div className='page'>
            <Button type={'white'} style={{ width: 'fit-content' }} className={'forPages'} rl={16}>
                <img src={calendar} alt="Иконка календаря в финансах" />
                <p>Выберите даты</p>
            </Button>
            <Table
                title={'Статистика'}
                columns={columns}
                data={data}
                // itemsPerPage={}
                onRowClick={false}
                onEdit={false}
                onStop={false}
            />
            <div className="statWithBtn">
                <Button type={'white'} style={{ width: 'fit-content', position: 'absolute', top: 30, right: 30 }} className={'forPages'} rl={16}>
                    <img src={calendar} alt="Иконка вывода прибыли белая" />
                    <p style={{ fontWeight: 600 }}>Выберите дату</p>
                </Button>
            </div>
        </div>
    )
}
