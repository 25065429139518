import React, { useState, useEffect } from 'react'
import '../Pages.css'

import { fetchApi } from '../../API/api';
import { formatNumber } from '../../utils/formatingDate';

import Table from '../../Components/Table/Table'
import Row from '../../Components/Table/Row'

import accept from '../../Assets/row/cardPlus.svg'
import cancel from '../../Assets/row/cardRemove.svg'
import good from '../../Assets/row/conversionGood.svg'
import middle from '../../Assets/row/conversionMiddle.svg'
import bad from '../../Assets/row/conversionBad.svg'

export default function Cascades() {
    const [loadingCascade, setLoadingCascade] = useState(true);
    const [dataPage, setDataPage] = useState(null);


    useEffect(() => {
        const fetchStatData = async () => {
            setLoadingCascade(true);
            try {
                const result = await fetchApi({
                    url: `/api/v1/dashboard/cascade`,
                });
                setDataPage(result);
            } catch (error) {
                console.error('Ошибка при загрузке данных:', error);
                // setError(error.message || 'Не удалось загрузить данные');
            } finally {
                setLoadingCascade(false);
            }
        };
        fetchStatData();
    }, []);

    const columns = [
        {
            header: 'Название',
            component: ({ data }) => (
                <Row
                    flex_out={'flex'}
                    gap_out={15}
                    icon={data.condition ? accept : cancel}
                    title={data.name}
                    text={""}
                />
            ),
            mobileHidden: false,
            desktopHidden: false,
        },
        {
            header: 'Баланс',
            component: ({ data }) => (
                <Row
                    usdt={true}
                    currency={"USDT"}
                    title={formatNumber(data?.balance) ?? 'N/A'}
                    text={"Осталось: " + formatNumber(data?.balance - data?.min_balance) ?? 'N/A'}
                />
            ),
            mobileHidden: false,
            desktopHidden: false,
        },
        {
            header: 'Конверсия',
            component: ({ data }) => (
                <Row
                    flex_out={'flex'}
                    gap_out={15}
                    icon={(data?.approve_deals / data?.deals * 100) >= 55 ? good : (data?.approve_deals / data?.deals * 100) >= 30 ? middle : bad}
                    title={formatNumber((data?.approve_deals / data?.deals * 100)) + "%"}
                    text={data?.approve_deals + "/" + data?.deals}
                />
            ),
            mobileHidden: false,
            desktopHidden: false,
        },
        {
            header: 'Объем',
            component: ({ data }) => (
                <Row
                    flex_out={'flex'}
                    gap_out={15}
                    title={formatNumber((data?.volume)) + " USDT"}
                />
            ),
            mobileHidden: false,
            desktopHidden: false,
        },
        // {
        //     header: 'Действие',
        //     component: ({ data }) => <Row />,
        //     mobileHidden: false,
        //     desktopHidden: false,
        // },
    ];

    return (
        <div className='page'>
            <Table
                title={'Каскады'}
                columns={columns}
                data={dataPage}
                // totalCount={dataPage?.length || 5}
                // itemsPerPage={5}
                rowsLoading={dataPage?.length || 5}
                onRowClick={false}
                onEdit={false}
                onStop={true}
                isLoading={loadingCascade}
            />
        </div>
    )
}
