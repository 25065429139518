import './MiniBlocks.css';
import React, { useEffect, useState } from 'react';

import { formatNumber } from '../../utils/formatingDate';
import { formattedDate } from '../../utils/formatingDate';

import deviceSvg from '../../Assets/row/device.svg'
import condition from '../../Assets/row/condition.svg'
import clock from '../../Assets/row/clock.svg'
import battery from '../../Assets/row/battery.svg'

export default function Device({ loading, device }) {

    const [imageLoaded, setImageLoaded] = useState(false);

    const preloadImages = (imageUrls) => {
        const validUrls = imageUrls.filter(url => url && typeof url === 'string');

        const promises = validUrls.map((url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = url;
                img.onload = () => resolve(url);
                img.onerror = () => reject(new Error(`Failed to load image: ${url}`));
            });
        });
        return Promise.all(promises);
    };

    useEffect(() => {
        const loadingImage = async () => {
            try {
                const imageUrls = [deviceSvg, condition, clock, battery];
                const loadedUrls = await preloadImages(imageUrls);
                console.log("Loaded images:", loadedUrls);
            } catch (error) {
                console.error(error.message);
            } finally {
                setImageLoaded(true);
            }
        };
        loadingImage();
    }, []);

    return (
        <div className="miniBlock">
            <h1>Устройство</h1>
            <div className="miniBlockRows">
                <div className="miniBlockRow">
                    <div className="miniBlockRowIcon">
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '24px', width: "24px" }} />
                        ) : (
                            <img src={deviceSvg} alt="Устройство" />
                        )}
                    </div>
                    <div className="miniBlockRowText">
                        <span>Устройство</span>
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '22px', width: "150px" }} />
                        ) : (
                            <p>{device?.token}</p>
                        )}
                    </div>
                </div>
                <div className="miniBlockRow">
                    <div className="miniBlockRowIcon">
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '24px', width: "24px" }} />
                        ) : (
                            <img src={condition} alt="Статус" />
                        )}
                    </div>
                    <div className="miniBlockRowText">
                        <span>Статус</span>
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '22px', width: "150px" }} />
                        ) : (
                            <p style={{ color: device?.condition ? "#14CD36" : "#EB001B" }}>{device?.condition ? "Активно" : "Приостановлено"}</p>
                        )}
                    </div>
                </div>
                <div className="miniBlockRow">
                    <div className="miniBlockRowIcon">
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '24px', width: "24px" }} />
                        ) : (
                            <img src={clock} alt="Дата" />
                        )}
                    </div>
                    <div className="miniBlockRowText">
                        <span>Последняя активность</span>
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '22px', width: "200px" }} />
                        ) : (
                            <p>{formattedDate(device?.date_update)}</p>
                        )}
                    </div>
                </div>
                <div className="miniBlockRow">
                    <div className="miniBlockRowIcon">
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '24px', width: "24px" }} />
                        ) : (
                            <img src={battery} alt="Заряд" />
                        )}
                    </div>
                    <div className="miniBlockRowText">
                        <span>Состояние</span>
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '22px', width: "100px" }} />
                        ) : (
                            <p>{formatNumber(device?.battery)}%</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}