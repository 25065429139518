import React from 'react';
import './PayinAndPayout.css';

import { formatNumber } from '../../utils/formatingDate';
import { formatTime } from '../../utils/formatingDate';

const DataInfo = ({ type, data = {}, isLoading }) => {

  const typeConfig = {
    all: {
      title: 'Всего сделок',
      render: () => (
        <>
          <div className="topData" style={{ height: 'auto', gap: '20px', paddingBottom: '25px' }}>
            <p>{typeConfig.all.title}</p>
            {isLoading ? (
              <div className="loading-pulse" style={{ height: "26px" }} />
            ) : (
              <h3>{formatNumber(data?.total_count) || '0'}</h3>
            )}
          </div>
          <div className="botData" style={{ gap: '16px' }}>
            <div className="botDataText">
              <p style={{ lineHeight: '13px' }}>Завершены</p>
              {isLoading ? (
                <div className="loading-pulse" style={{ height: "13px", width: "15%" }} />
              ) : (
                <h3>{formatNumber(data?.approve_count) || '0'}</h3>
              )}
            </div>
            <div className="botDataText">
              <p style={{ lineHeight: '13px' }}>Средний чек</p>
              {isLoading ? (
                <div className="loading-pulse" style={{ height: "13px" }} />
              ) : (
                <h3>{formatNumber(data?.average_amount) || '0'} RUB</h3>
              )}
            </div>
          </div>
        </>
      )
    },
    accept: {
      title: 'Подтвержденные',
      render: () => (
        <div className="topData">
          <p>{typeConfig.accept.title}</p>
          <div className="botData">
            {isLoading ? (
              <>
                <div className="loading-pulse" style={{ height: "26px", width: "45%" }} />
                <div className="loading-pulse" style={{ height: "13px" }} />
              </>
            ) : (
              <>
                <h3>{formatNumber(data?.total_amount) || '0'} RUB</h3>
                <p>{formatNumber(data?.total_amount_usdt) || '0'} USDT</p>
              </>
            )}

          </div>
        </div>
      )
    },
    profit: {
      title: 'Прибыль',
      render: () => (
        <div className="topData">
          <p>{typeConfig.profit.title}</p>
          {isLoading ? (
            <div className="loading-pulse" style={{ height: "26px" }} />
          ) : (
            <h3>{formatNumber(data?.profit_amount) || '0'} USDT</h3>
          )}
        </div>
      )
    },
    profit_admin: {
      title: 'Прибыль',
      render: () => (
        <>
          <div className="topData" style={{ height: 'auto', gap: '20px', paddingBottom: '25px' }}>
            <p>{typeConfig.profit.title}</p>
            {isLoading ? (
              <div className="loading-pulse" style={{ height: "26px" }} />
            ) : (
              <h3>{formatNumber(data?.profit_amount + data?.profit_rate) || '0'} USDT</h3>
            )}
          </div>
          <div className="botData" style={{ gap: '16px' }}>
            <div className="botDataText">
              <p style={{ lineHeight: '13px' }}>Процент</p>
              {isLoading ? (
                <div className="loading-pulse" style={{ height: "13px", width: "25%" }} />
              ) : (
                <h3>{formatNumber(data?.profit_amount) || '0'} USDT</h3>
              )}
            </div>
            <div className="botDataText">
              <p style={{ lineHeight: '13px' }}>Курс</p>
              {isLoading ? (
                <div className="loading-pulse" style={{ height: "13px", width: "25%" }} />
              ) : (
                <h3>{formatNumber(data?.profit_rate) || '0'} USDT</h3>
              )}
            </div>
          </div>
        </>
      )
    },
    active_devices: {
      title: 'Споры',
      render: () => (
        <div className="smallData">
          <p>{typeConfig.active_devices.title}</p>
          {isLoading ? (
            <div className="loading-pulse" style={{ height: "26px" }} />
          ) : (
            <h3>{formatNumber(data?.dispute_count) || '0'}</h3>
          )}
        </div>
      )
    },
    active_req: {
      title: 'Обработка платежа',
      render: () => (
        <div className="smallData">
          <p>{typeConfig.active_req.title}</p>
          {isLoading ? (
            <div className="loading-pulse" style={{ height: "26px" }} />
          ) : (
            <h3>{formatNumber(data?.payment_duration) || '0'}</h3>
          )}
        </div>
      )
    },
    time: {
      title: 'Не выдача реквизитов',
      render: () => (
        <div className="smallData">
          <p>{typeConfig.time.title}</p>
          {isLoading ? (
            <div className="loading-pulse" style={{ height: "26px", width: "40%" }} />
          ) : (
            <h3>{formatNumber(data?.nr_sum) || '0'} RUB</h3>
          )}
        </div>
      )
    },
    sum: {
      title: 'Сумма отклоненных заявок',
      render: () => (
        <div className="smallData">
          <p>{typeConfig.sum.title}</p>
          <h3>{data?.sum || '0'} USDT</h3>
        </div>
      )
    },
    conversion: {
      title: 'Конверсия выплат трейдера',
      render: () => (
        <div className="smallData">
          <p>{typeConfig.conversion.title}</p>
          <h3>{data?.conversion || '0'}%</h3>
        </div>
      )
    }
  };

  const config = typeConfig[type] || {
    title: 'Неизвестный тип',
    render: () => (
      <div className="smallData">
        <p>Неизвестный тип</p>
        <h3>0</h3>
      </div>
    )
  };

  return <div className="dataContainer">{config.render()}</div>;

};

export default function PayinAndPayout({ name, data, isLoading }) {
  const typeUser = localStorage.getItem("fp_type");
  return (
    <div className="ppContainer">
      <h1 className='ppTitle'>{name}</h1>
      <div className='dataCollection'>
        <div className="topDataCollection">
          <DataInfo type="all" data={data} isLoading={isLoading} />
          <DataInfo type="accept" data={data} isLoading={isLoading} />
          {typeUser !== "Admin" && <DataInfo type="profit" data={data} isLoading={isLoading} />}
          {typeUser === "Admin" && <DataInfo type="profit_admin" data={data} isLoading={isLoading} />}
        </div>
        <div className="botDataCollection">
          <div className="leftSideDataCollection">
            <DataInfo type={name === 'Payout' ? 'sum' : 'active_devices'} data={data} isLoading={isLoading} />
            {name === 'Payin' ? <DataInfo type='active_req' data={data} isLoading={isLoading} /> : null}
          </div>
          <DataInfo type={name === 'Payout' ? 'conversion' : 'time'} data={data} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
}