import './DisputesDashboard.css'
import React, { useState, useEffect } from 'react'
import { fetchApi } from '../../API/api'
import { formattedDate } from '../../utils/formatingDate'
import { formatNumber } from '../../utils/formatingDate'



import Table from '../Table/Table'
import Row from '../Table/Row'

import waiting from '../../Assets/row/waiting.svg'
import accept from '../../Assets/row/cardPlus.svg'
import cancel from '../../Assets/row/cardRemove.svg'

export default function DisputesDashboard() {
    const filters = ["Все", "Открытый спор", "Спор принят", "Спор отклонен"];
    const [activeFilter, setActiveFilter] = useState("Все");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchDisputeData = async () => {
            setLoading(true);
            try {
                const result = await fetchApi({
                    url: `/api/v1/dashboard/dispute`,
                    params: { page: currentPage, page_size: pageSize },
                });
                setData(result);
            } catch (error) {
                console.error('Ошибка при загрузке данных:', error);
                // setError(error.message || 'Не удалось загрузить данные');
            } finally {
                setLoading(false);
            }
        };
        if (pageSize !== null) {
            fetchDisputeData();
        }

    }, [currentPage, pageSize]);

    const columns = [
        {
            header: 'Статус',
            component: ({ data }) => (
                <Row
                    flex_out={'flex'}
                    gap_out={15}
                    icon={data.condition === null ? waiting : data.condition ? accept : cancel}
                    title={data.condition === null ? 'Открытый спор' : data.condition ? 'Спор принят' : 'Спор отклонен'}
                    text={formattedDate(data.create)}
                />
            ),
        },
        {
            header: 'Сумма',
            component: ({ data }) => (
                <Row
                    usdt={true}
                    title={data?.payment?.amount ? formatNumber(data.payment.amount) + ' ' + data.payment.currency : 'N/A'}
                    text={(data?.payment?.amount_usdt).toFixed(2) ?? 'N/A'}
                />
            ),
        },
    ];

    const handleFilterClick = (filter) => {
        setActiveFilter(filter);
        console.log(`Выбран фильтр: ${filter}`);
    };
    return (
        <div className='disputesDashboard'>
            <Table
                title={'Споры'}
                columns={columns}
                data={data?.results || []}
                totalCount={data?.total_pages || 0}
                rowsLoading={pageSize}
                onRowClick={true}
                onEdit={false}
                onStop={false}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                isLoading={loading}
                className={'smallDispute'}
                setPageSize={setPageSize}
            />
            {/* <Loader isLoading={loading} targetId='tableDisputeSmall' /> */}
        </div>
    )
}
