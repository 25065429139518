import './MiniBlocks.css';
import React, { useEffect, useState } from 'react';

import { formatCardNumber } from '../../utils/formatingDate';
import { formatNumber } from '../../utils/formatingDate';

import phone from '../../Assets/row/phone.svg'
import cardSvg from '../../Assets/row/card.svg'
import profile from '../../Assets/row/profile.svg'
import condition from '../../Assets/row/condition.svg'
import limit from '../../Assets/row/limit.svg'
import percent from '../../Assets/row/approve_percent.svg'
import bank from '../../Assets/row/bank.svg'

export default function Detail({ loading, payment, card }) {

    const [imageLoaded, setImageLoaded] = useState(false);

    const preloadImages = (imageUrls) => {
        const validUrls = imageUrls.filter(url => url && typeof url === 'string');

        const promises = validUrls.map((url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = url;
                img.onload = () => resolve(url);
                img.onerror = () => reject(new Error(`Failed to load image: ${url}`));
            });
        });
        return Promise.all(promises);
    };

    useEffect(() => {
        const loadingImage = async () => {
            try {
                const imageUrls = [phone, cardSvg, profile, condition, limit, percent, bank, card?.bank_logo];
                const loadedUrls = await preloadImages(imageUrls);
                console.log("Loaded images:", loadedUrls);
            } catch (error) {
                console.error(error.message);
            } finally {
                setImageLoaded(true);
            }
        };
        setImageLoaded(false);
        loadingImage();
    }, [card]);

    return (
        <div className="miniBlock">
            <h1>Реквизит</h1>
            <div className="miniBlockRows">
                {card?.phone_number && (
                    <div className="miniBlockRow">
                        <div className="miniBlockRowIcon">
                            {loading && imageLoaded ? (
                                <div className="loading-pulse" style={{ height: '24px', width: "24px" }} />
                            ) : (
                                <img src={phone} alt="Реквизит" />
                            )}
                        </div>
                        <div className="miniBlockRowText">
                            <span>Номер телефона</span>
                            {loading && imageLoaded ? (
                                <div className="loading-pulse" style={{ height: '22px', width: "220px" }} />
                            ) : (
                                <p>{card?.phone_number}</p>
                            )}
                        </div>
                    </div>
                )}
                {card?.card_number && (
                    <div className="miniBlockRow">
                        <div className="miniBlockRowIcon">
                            {loading ? (
                                <div className="loading-pulse" style={{ height: '24px', width: "24px" }} />
                            ) : (
                                <img src={cardSvg} alt="Реквизит" />
                            )}
                        </div>
                        <div className="miniBlockRowText">
                            <span>Номер карты</span>
                            {loading ? (
                                <div className="loading-pulse" style={{ height: '22px', width: "220px" }} />
                            ) : (
                                <p>{formatCardNumber(card?.card_number)}</p>
                            )}
                        </div>
                    </div>
                )}
                {loading && imageLoaded && (
                    <div className="miniBlockRow">
                        <div className="miniBlockRowIcon">
                            <div className="loading-pulse" style={{ height: '24px', width: "24px" }} />
                        </div>
                        <div className="miniBlockRowText">
                            <span>Номер телефона</span>
                            <div className="loading-pulse" style={{ height: '22px', width: "220px" }} />
                        </div>
                    </div>
                )}
                <div className="miniBlockRow">
                    <div className="miniBlockRowIcon">
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '24px', width: "24px" }} />
                        ) : (
                            <img src={card?.bank_logo ? card.bank_logo : bank} alt="Банк" className={card?.bank_logo ? 'round' : ''} />
                        )}
                    </div>
                    <div className="miniBlockRowText">
                        <span>Банк</span>
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '22px', width: "120px" }} />
                        ) : (
                            <p>{card?.bank}</p>
                        )}
                    </div>
                </div>
                <div className="miniBlockRow">
                    <div className="miniBlockRowIcon">
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '24px', width: "24px" }} />
                        ) : (
                            <img src={profile} alt="ФИО" />
                        )}
                    </div>
                    <div className="miniBlockRowText">
                        <span>ФИО</span>
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '22px', width: "300px" }} />
                        ) : (
                            <p>{card?.full_name}</p>
                        )}
                    </div>
                </div>
                <div className="miniBlockRow">
                    <div className="miniBlockRowIcon">
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '24px', width: "24px" }} />
                        ) : (
                            <img src={condition} alt="Статус" />
                        )}
                    </div>
                    <div className="miniBlockRowText">
                        <span>Статус</span>
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '22px', width: "150px" }} />
                        ) : (
                            <p style={{ color: card?.condition ? "#14CD36" : "#EB001B" }}>{card?.condition ? "Активно" : "Приостановлено"}</p>
                        )}
                    </div>
                </div>
                <div className="miniBlockRow">
                    <div className="miniBlockRowIcon">
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '24px', width: "24px" }} />
                        ) : (
                            <img src={limit} alt="Статус" />
                        )}
                    </div>
                    <div className="miniBlockRowText">
                        <span>Лимит</span>
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '22px', width: "200px" }} />
                        ) : (
                            <p>{formatNumber(card?.day_volume)}/{formatNumber(card?.day_limit)} {payment?.currency} </p>
                        )}
                    </div>
                </div>
                <div className="miniBlockRow">
                    <div className="miniBlockRowIcon">
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '24px', width: "24px" }} />
                        ) : (
                            <img src={percent} alt="Статус" />
                        )}
                    </div>
                    <div className="miniBlockRowText">
                        <span>Конверсия</span>
                        {loading && imageLoaded ? (
                            <div className="loading-pulse" style={{ height: '22px', width: "120px" }} />
                        ) : (
                            <p>{formatNumber((card?.approve_deals / card?.deals * 100))}%, {formatNumber(card?.approve_deals)}/{formatNumber(card?.deals)}</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}