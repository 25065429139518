import React, { useState } from 'react';
import './DisputeModal.css';

import { fetchApi } from '../../../API/api';

import Button from '../../Buttons/Button';

import close from '../../../Assets/svg/closeCircle.svg';
import accept from '../../../Assets/svg/acceptWhite.svg';

export default function DisputeModal({ onClose, windowWidth, typeDispute, data, reasons, setData, setLoading }) {
    const [selectedReason, setSelectedReason] = useState("");
    const [amount, setAmount] = useState(data.amount);
    const [isChecked, setIsChecked] = useState(false);
    const [isSumChecked, setIsSumChecked] = useState(typeDispute);

    const fetchDispute = async () => {
        onClose();
        setLoading(true)
        try {
            const body = {
                dispute_status: typeDispute,
                dispute_reason: selectedReason === "" ? null: selectedReason,
                payment: data.payment.payment_id,
                payment_status: typeDispute ? "AC" : "CL",
                payment_amount: amount,
            }
            console.log(body);
            const result = await fetchApi({
                url: `/api/v1/dashboard/dispute/${data.id}`,
                method: "PUT",
                data: body,
            });
            setData(result);
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="disputeOverlay" onClick={onClose}>
            <div className="disputeModal" onClick={(e) => e.stopPropagation()}>
                <div className="disputeTitle">
                    <h1>{typeDispute ? "Принятие спора" : "Отклонение спора"}</h1>
                    <p>Перевод средств с баланса прибыли</p>
                </div>
                {typeDispute && (
                    <div className="dataCorrect" onClick={() => setIsSumChecked(!isSumChecked)}>
                        <button
                            className="checkbox"
                            style={{
                                background: isSumChecked ? 'linear-gradient(222.4deg, #FF7700 6.42%, #E10CFF 93.77%)' : '',
                                border: isSumChecked ? 'none' : ''
                            }}
                        >
                            {isSumChecked && (
                                <svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} viewBox="0 0 24 24">
                                    <path fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.5} d="M20 7L10 17l-5-5" />
                                </svg>
                            )}
                        </button>
                        {isSumChecked ? (
                            <p>Сумма спора соответствует сумме пополнения</p>
                        ) : (
                            <div className="inputWD" onClick={(e) => e.stopPropagation()}>
                                <input
                                    type="text"
                                    placeholder={amount}
                                    value={amount}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value === '' || /^\d*$/.test(value)) {
                                            setAmount(value === '' ? '' : Number(value));
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight'];
                                        if (!/[0-9]/.test(e.key) && !allowedKeys.includes(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    min="1"
                                    autoFocus
                                />
                            </div>
                        )}
                    </div>
                )}
                <div className="dataCorrect" onClick={() => setIsChecked(!isChecked)}>
                    <button
                        className="checkbox"
                        style={{
                            background: isChecked ? 'linear-gradient(222.4deg, #FF7700 6.42%, #E10CFF 93.77%)' : '',
                            border: isChecked ? 'none' : ''
                        }}
                    >
                        {isChecked && (
                            <svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} viewBox="0 0 24 24">
                                <path fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.5} d="M20 7L10 17l-5-5" />
                            </svg>
                        )}
                    </button>
                    <p>Вы уверены, что <b>{typeDispute ? "принимаете" : "отклоняете"}</b> спор?</p>
                </div>
                {!typeDispute && (
                    <div className="dataSelect">
                        <p>Выберите причину отклонения</p>
                        <select
                            name="reasons"
                            id="reasons"
                            value={selectedReason}
                            style={{ borderColor: selectedReason === "" ? "red" : "" }}
                            onChange={(e) => setSelectedReason(e.target.value)}
                            placeholder="Выберите причину"
                        >
                            <option value="" disabled hidden>Выберите причину</option>
                            {reasons.map((reason, index) => (
                                <option key={index} value={reason.id}>
                                    {reason.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                <div className="disputeButtons">
                    <Button
                        type={typeDispute ? (isChecked ? 'colored' : '') : (isChecked && selectedReason !== '' ? 'colored' : '')}
                        disabled={typeDispute ? !isChecked : !(isChecked && selectedReason !== '')}
                        onClick={fetchDispute}
                        rl={windowWidth <= 370 ? 12 : 16}
                        tb={windowWidth <= 370 ? 6 : windowWidth <= 780 ? 8 : 10}
                    >
                        <img src={accept} alt="Иконка подтверждения вывода" />
                        <p>Подтвердить</p>
                    </Button>
                    <Button type="white" onClick={onClose} rl={windowWidth <= 370 ? 12 : 16} tb={windowWidth <= 370 ? 6 : windowWidth <= 780 ? 8 : 10}>
                        <img src={close} alt="Иконка отмены вывода" />
                        <p>Отмена</p>
                    </Button>
                </div>
            </div>
        </div>
    );
}