import React, { useState, useEffect } from 'react'
import '../Pages.css'
import { fetchApi } from '../../API/api'

import Table from '../../Components/Table/Table'
import CalendarModal from '../../Components/Modals/Calendar/CalendarModal'
import Button from '../../Components/Buttons/Button'
import Search from '../../Components/Search/Search'

import arrow from '../../Assets/svg/arrowChange.svg'
import on from '../../Assets/row/accept.svg'
import off from '../../Assets/row/cancel.svg'


import { columnsOffice } from './TransactionRules';
import { columnsAdminSupport } from './TransactionRules';

export default function Transactions() {
    const [tranState, setTranState] = useState('Все')
    const [showTranStates, setShowTranStates] = useState(true)
    const [showCalendar, setShowCalendar] = useState(false);
    const [date, setDate] = useState('Все время')
    const [pageSize, setPageSize] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const handleCalendarApply = (range) => {
        if (!range?.start) return;

        const today = new Date().toLocaleDateString('ru-RU');
        const startDate = range.start.toLocaleDateString('ru-RU');
        const startEpoch = range.start.getTime();
        const epoch1970 = new Date(1970, 0, 1).getTime();

        if (startEpoch === epoch1970 && range.end?.toLocaleDateString('ru-RU') === today) {
            setDate('Все время');
        } else if (!range.end || startDate === range.end.toLocaleDateString('ru-RU')) {
            setDate(startDate === today ? 'Сегодня' : startDate);
        } else {
            setDate(`${startDate} - ${range.end.toLocaleDateString('ru-RU')}`);
        }
    };

    const handleCalendarClick = () => {
        setShowCalendar(!showCalendar);
    };

    const fetchPaymentData = async () => {
        try {
            const result = await fetchApi({
                url: `/api/v1/dashboard/payment`,
                params: { page: currentPage, page_size: pageSize },
            });
            setData(result);
        } catch (error) {
            console.error('Ошибка при загрузке данных:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (refresh) {
            const interval = setInterval(fetchPaymentData, 7000);
            return () => clearInterval(interval);
        }
    }, [refresh]);

    useEffect(() => {
        setLoading(true);
        if (pageSize !== null) {
            fetchPaymentData();
        }
    }, [currentPage, pageSize]);


    const type = localStorage.getItem("fp_type");
    const columns = (type === "Office" ? columnsOffice : columnsAdminSupport);

    return (
        <div className='page'>
            {showCalendar && <CalendarModal onClose={handleCalendarClick} onApply={handleCalendarApply} />}
            <div className="buttonsContainer">
                <Search className='pagesSearch' />
                <Button
                    type={'white'}
                    style={{ width: 'auto' }}
                    tb={showCalendar ? 9 : 10}
                    rl={showCalendar ? 15 : 16}
                    onClick={() => setShowTranStates(!showTranStates)}
                >
                    <img src={arrow} alt="Иконка вывода модалки" style={{ transform: 'rotate(180deg)' }} />
                    <span style={{ color: '#2B2B2A', fontWeight: showCalendar ? '700' : '400' }}>{tranState}</span>
                    <div className='btnModal'>
                        {/* <span>Все</span>
                        <span>Сделка завершена</span> */}
                    </div>
                </Button>
                <Button
                    type={'white'}
                    style={{ width: 'auto' }}
                    tb={refresh ? 9 : 10}
                    rl={refresh ? 15 : 16}
                    onClick={() => setRefresh(!refresh)}
                >
                    <img src={refresh ? on : off} alt="Иконка вывода модалки" />
                    <span style={{ color: '#2B2B2A', fontWeight: refresh ? '700' : '400' }}>Поток сделок</span>
                </Button>
            </div>
            <div className="tableWithBtn" id='tableTran'>
                <Table
                    title={'Сделки'}
                    columns={columns}
                    data={data?.results || []}
                    totalCount={data?.total_pages || 0}
                    itemsPerPage={pageSize}
                    rowsLoading={pageSize}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                    isLoading={loading}
                    onRowClick={true}
                    onEdit={false}
                    onStop={false}
                />
            </div>
        </div>
    )
}
