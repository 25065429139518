import React, { useState, useEffect, useRef } from 'react';
import './Balance.css';

import IconWrapper from '../IconWrapper/IconWrapper';

import message from '../../Assets/svg/messageQuestion.svg'

const Balance = () => {
    const [currentTime, setCurrentTime] = useState(new Date());
    const [showTooltip, setShowTooltip] = useState(false);
    const [typeUser, setTypeUser] = useState("Office");
    const [balance, setBalance] = useState(0);
    const [balanceSecond, setBalanceSecond] = useState(0);
    const [loadingBalance, setLoadingBalance] = useState(true);
    const [rate, setRate] = useState(0);
    const socketRef = useRef(null);
    const reconnectTimeout = useRef(null);

    useEffect(() => {
        setTypeUser(localStorage.getItem("fp_type"));
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const handleImageClick = () => {
        setShowTooltip(true);
        setTimeout(() => {
            setShowTooltip(false);
        }, 3000);
    };

    useEffect(() => {
        const token = localStorage.getItem("fp_token");
        const type = localStorage.getItem("fp_type");
        if (!token || !type) {
            console.error("Отсутствуют токен или тип в localStorage");
            return;
        }

        const socketUrl = `wss://api.fastpaygate.ru/api/websocket/${token}/${type}`;

        const connectWebSocket = () => {
            if (socketRef.current) {
                console.log("Закрытие предыдущего соединения...");
                socketRef.current.close();
            }

            console.log("Подключение к WebSocket...");

            socketRef.current = new WebSocket(socketUrl);

            socketRef.current.onopen = () => {
                console.log("Соединение установлено");
            };

            socketRef.current.onmessage = (event) => {
                if (loadingBalance) {
                    setLoadingBalance(false);
                }
                try {
                    const data = JSON.parse(event.data);
                    console.log(data)
                    if (data?.message === "balance") {
                        setBalance(Number(data.balance.toFixed(2)));
                    } else if (data?.message === "balance_office") {
                        setBalance(Number(data.balance.toFixed(2)))
                    } else if (data?.message === "balance_merchant") {
                        setBalanceSecond(Number(data.balance.toFixed(2)))
                    } else if (data?.message === "rate") {
                        setRate(data.rate.toFixed(2));
                    } else if (data?.message === "profit") {
                        console.log("Получена информация о прибыли:", data.profit);
                    } else {
                        console.error("Поле 'message' отсутствует или пусто:", data);
                    }
                } catch (error) {
                    console.error("Ошибка при разборе JSON:", error);
                }
            };

            socketRef.current.onerror = (error) => {
                console.error("Ошибка WebSocket:", error);
            };

            socketRef.current.onclose = () => {
                console.log("Соединение закрыто. Попытка переподключения через 5 секунд...");
                reconnectTimeout.current = setTimeout(connectWebSocket, 5000);
            };
        };
        setLoadingBalance(true);
        connectWebSocket();

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
            clearTimeout(reconnectTimeout.current);
        };
    }, []);

    return (
        <div className="balanceContainer">
            <div className="balanceRow">
                {loadingBalance ? (
                    <div className="loading-pulse" style={{ height: "44px" }} />
                ) : (
                    <h2>{rate} RUB</h2>
                )}
                <p>
                    {currentTime.toLocaleString('ru-RU', {
                        day: '2-digit',
                        month: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                    })}
                </p>
            </div>
            {typeUser === "Office" && (
                <>
                    <div className="balanceRow">
                        <h2>{'33'} USDT</h2>
                        <div className="balanceDeposite">
                            {showTooltip && (
                                <div className={`tooltip ${showTooltip ? 'tooltip-visible' : ''}`}>
                                    <p>Эскроу счет</p>
                                    <h3>{'0'} USDT</h3>
                                    <div className="triangle"></div>
                                    <div className="triangleGrey"></div>
                                </div>
                            )}
                            <p>Баланс депозита</p>
                            <IconWrapper height='23px' onClick={handleImageClick}>
                                <img
                                    src={message}
                                    alt="Иконка баланса депозита"
                                    style={{ transform: 'translate(1px, 2px)' }}
                                />
                            </IconWrapper>
                        </div>
                    </div>
                    <div className="balanceRow">
                        <h2>{'1'} USDT</h2>
                        <p>Баланс вывода</p>
                    </div>
                    <div className="balanceRow">
                        <h2>{'1'} ₽</h2>
                        <p>Payout</p>
                    </div>
                </>
            )}
            {typeUser === "Admin" && (
                <>
                    <div className="balanceRow">
                        {loadingBalance ? (
                            <div className="loading-pulse" style={{ height: "44px", width: "50%" }} />
                        ) : (
                            <h2>{balance} USDT</h2>
                        )}
                        <p>Баланс офисов</p>
                    </div>
                    <div className="balanceRow">
                        {loadingBalance ? (
                            <div className="loading-pulse" style={{ height: "44px", width: "40%" }} />
                        ) : (
                            <h2>{balanceSecond} USDT</h2>
                        )}
                        <p>Баланс мерчантов</p>
                    </div>
                </>
            )}
        </div>
    );
};

export default Balance;